<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :urlModelName="urlModelName"
      :label="label"
      :fields="fields"
      :leftFields="_leftFields"
      :rightFields="rightFields"
      v-model="modelData.area"
    ></WsReadSection>
    <WsCrud
      v-if="modelData.area&&$config.wsmodule.area.area_section!=false"
      class="mt-40"
      :modelName="$store.state.stone_model.area_section.modelName"
      :label="$store.state.stone_model.area_section.label"
      :fields="$store.state.stone_model.area_section.fields"
      :showFields="showFields.area_section"
      :infiniteScroll="true"
      :params="_params"
      :createData="_params"
      :updateData="_params"
    ></WsCrud>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/area";
export default {
  data: () => ({
    modelName: model.modelName,
    urlModelName: model.urlModelName,
    label: model.label,
    fields: model.fields,
    rightFields: ["id", "sequence"],
    modelData: {
      area: null,
    },
    showFields: {
      area_section: ["id", "name"],
    },
  }),

  computed: {
    _leftFields() {
      const _leftFields = ["name"];
      if (this.$config.wsmodule.country_code) {
        _leftFields.push("country_code");
      }
      return _leftFields;
    },
    _params() {
      return {
        area: this._id,
      };
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>